<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <el-row class="cont-form-box">
      <el-form ref="form" :model="ruleForm" :rules="rules">
        <el-form-item label="活动名称" prop="mainTitle">
          <el-input class="width-370" v-model="ruleForm.mainTitle" placeholder="活动名称" :disabled="action === 'look'"></el-input>
        </el-form-item>
        <el-form-item label="特惠时段" prop="startDate">
          <DatePacker class="width-370" :datePacker="dateRange" :disabled="action === 'look'" format="yyyy-MM-dd" @setDatePacker="getOrderDatePacker"/>
        </el-form-item>
        <el-form-item label="特惠商品" prop="productIdList">
          <el-button @click="openGoodsPop" type="primary" :disabled="action === 'look'">商品选择</el-button>
        </el-form-item>
      </el-form>
      <div class="table-box">
        <el-table ref="table" :data="checkedGoods" border fit>
          <el-table-column prop="id" label="序号"></el-table-column>
          <el-table-column prop="name" label="商品名称"></el-table-column>
          <el-table-column prop="sumQuantity" label="商品库存"></el-table-column>
          <el-table-column prop="skuNum" label="在售套餐数"></el-table-column>
          <el-table-column prop="showPrice" label="套餐最低价"></el-table-column>
        </el-table>
      </div>
      <div class="m-top-15">
        <el-button @click="handleReset" :disabled="action === 'look'">重置</el-button>
        <el-button @click="handleSave" type="primary" :disabled="action === 'look'">保存</el-button>
      </div>
    </el-row>
    <el-dialog title="商品选择" :visible.sync="visible" width="650px">
      <el-row class="m-bottom-20">
        <label>商品名称：</label>
        <el-input v-model="name" class="width-200 m-right-5" clearable></el-input>
        <el-button @click="getProductList" type="primary">搜索</el-button>
      </el-row>
      <div class="goods-box">
        <el-table ref="goodsForm" :data="goodsData" border :stripe="true" fit
                  @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="name" label="商品名称"></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">{{ scope.row.status | filterStatus(that) }}</template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button @click="saveCheckGoods" type="primary">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getGoodsPage, getNotActiveProduct, savePreference } from '@/api/shop/promotion/preferential'
import { mapState } from 'vuex'
import { getDict } from "@/common/js/common";
export default {
  name: 'crumbs',
  data() {
    return {
      crumbs: new Map([
        ['特惠商城'], ['促销中心'], ['限时特惠'], ['新建特惠商品']
      ]),
      that: this,
      action: 'create',
      dateRange: [],
      id: '',
      ruleForm: {
        mainTitle: '',
        startDate: '',
        endDate: '',
        productIdList: [],
        dateType: 'DATE',
        status: 'ACTIVE',
        type: 'PREFERENCE',
        mon: 'YES',
        tue: 'YES',
        wed: 'YES',
        thu: 'YES',
        fri: 'YES',
        sat: 'YES',
        sun: 'YES',
        seckillPrice: '0.1'
      },
      rules: {
        mainTitle: [{ required: true, message: '活动名称不能为空！' }],
        startDate: [{ required: true, message: '特惠时段不能为空！' }],
        productIdList: [{ required: true, message: '特惠商品不能为空！' }]
      },
      name: '',
      visible: false,
      goodsData: [],
      interimCheckedGoods: [],
      checkedGoods: []
    }
  },
  computed: {
    ...mapState(['hotelInfo', 'dictData'])
  },
  mounted() {
    this.action = this.$route.query.action
    getDict('goods-status')
    this.getProductList()
    if (this.action === 'look' || this.action === 'update') this.getPreferenceInfo()
  },
  methods: {
    // 获取特惠活动信息
    getPreferenceInfo() {
      const preferenceInfo = JSON.parse(sessionStorage.getItem('preferenceInfo'))
      for (const k in this.ruleForm) k in preferenceInfo && (this.ruleForm[k] = preferenceInfo[k])
      this.dateRange = [ this.ruleForm.startDate, this.ruleForm.endDate ]
      this.id = preferenceInfo.id
      this.lookGoodsList(this.id)
    },
    // 查看商品列表
    lookGoodsList(id) {
      const params = { limit: 99999, page: 1, id:this.hotelInfo.id }
      getGoodsPage(params).then(({ success, records }) => {
        if (!success) return;
        this.checkedGoods = records.map(i => {
          return { id: i.id, name: i.name, skuNum: i.skuNum, sumQuantity: i.sumQuantity,
            showPrice: i.showPrice , status: i.status }
        })
        this.ruleForm.productIdList = this.checkedGoods.map(i => i.id)
      })
    },
    // 打开商品会话框
    openGoodsPop() {
      this.visible = true
      this.action ==='edit' && this.checkedGoods.forEach(i => {
        this.$nextTick(() => this.$refs.goodsForm.toggleRowSelection(this.goodsData.find(r => i.id === r.id)))
      })
    },
    // 获取特惠时段
    getOrderDatePacker(val) {
      this.dateRange = val
      const [ startDate, endDate ] = [ ...this.dateRange ];
      this.ruleForm.startDate = startDate
      this.ruleForm.endDate = endDate
    },
    // 获取商品列表
    getProductList() {
      const params = { name: this.name, limit: 99999, page: 1 , hotelId: this.hotelInfo.id }
      getNotActiveProduct(params).then(({ success, records }) => {
        if (success) this.goodsData = records.map(i => {
          return { id: i.id, name: i.name, skuNum: i.skuNum, sumQuantity: i.sumQuantity,
            showPrice: i.showPrice, status: i.status }
        })
      })
    },
    // 处理多选商品
    handleSelectionChange(val) {
      this.interimCheckedGoods = val
    },
    // 保存选中商品
    saveCheckGoods() {
      this.visible = false
      this.ruleForm.productIdList = this.interimCheckedGoods.map(i => i.id)
      this.checkedGoods = [ ...this.interimCheckedGoods ]
    },
    // 保存特惠商品
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        const params = {
          hotelId: this.hotelInfo.id,
          companyId: this.hotelInfo.storeId,
          ...this.ruleForm
        }
        if (this.action === 'update') params.id = this.id
        savePreference(params, this.action).then(({ success }) => {
          if (!success) return;
          this.$router.go(-1)
          this.$message({ message: '保存成功！', type: 'success' })
        })
      })
    },
    // 重置表单
    handleReset() {
      this.ruleForm.mainTitle = ''
      this.dateRange = []
      this.ruleForm.startDate = ''
      this.ruleForm.productIdList.length = 0
      this.checkedGoods = []
    }
  },
  filters: {
    filterStatus(val, that) {
      if (val && that.dictData['goods-status']) return that.dictData['goods-status'][val] || val
    }
  }
}
</script>
<style lang="scss" scoped>
.cont {
  .table-box { max-height: 700px; overflow-y: auto }
  .goods-box { height: 500px; overflow-y: auto }
  ::v-deep .el-form-item__error{ margin-left: 77px }
}
</style>

